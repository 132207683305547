<template>
  <v-row class="mt-8 mb-8">
    <v-col>
      <h2 class="m0">{{ title }}</h2>
      <span>{{ subtitle }}</span>
    </v-col>

    <v-col v-if="!hideAction" cols="3" class="vc text-right">
      <v-btn v-if="!isMobile" outlined @click="goto" color="primary" class="nott bold">
        View All
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn v-else fab outlined x-small color="primary"><v-icon>mdi-chevron-right</v-icon></v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: [
    'routeTo',
    'slug',
    'title',
    'subtitle',
    'hideAction'
  ],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  methods: {
    goto() {
      if (this.routeTo == 'categories') {
        this.$router.push({ name: 'categories' });
      }

      else if (['products', 'group'].includes(this.routeTo)) {
        this.$router.push({ name: 'collection', params: { slug: this.slug } });
      }
    }
  }
}
</script>