<template>
  <v-container>
    <NotFound v-if="loadingCompleted"></NotFound>
    <SkeletonLoader v-else :pre-loading="true"></SkeletonLoader>
  </v-container>
</template>

<script>
import $ from 'jquery';
import NotFound from '../../components/not-found/not-found.vue';
import SkeletonLoader from '../../components/skeleton-loader/skeleton-loader.vue';

export default {
  name: 'PageNotFound',
  
  created() {
    $('html, body').animate({ scrollTop: 0 }, 0);
  },

  components: {
    NotFound,
    SkeletonLoader
  },

  computed: {
    loadingCompleted() {
      return this.$store.state.loadingCompleted;
    }
  },

  data() {
    return {
      emptyCartGIF: require("@/assets/imgs/empty-cart.gif")
    }
  }
}
</script>