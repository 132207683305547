import store from "../store";
import Util from "./util";

export default {
  add(product) {
    const oldProduct = this.findProduct(product);
    if (oldProduct) {
      oldProduct.qty = oldProduct.qty + (oldProduct.incrementor || 1);
      oldProduct.price = this.getPrice(product);
    } else {
      let photos = [];
      if (product.photos.length) photos = product.photos;
      else if (
        product.children &&
        product.children.length &&
        product.children.find((x) => !x.parent)
      )
        photos = product.children.find((x) => !x.parent).photos;

      let price = this.getPrice(product); //product.price;
      let minOrderCap = this.getMinOrderCap(product);

      const productObject = {
        qty: minOrderCap,
        quantity: product.quantity,
        _id: product._id,
        name: product.name,
        beforeTaxPrice: product.beforeTaxPrice,
        price: price,
        photos: photos,
        marketPrice: product.marketPrice,
        purchasePrice: product.purchasePrice,
        children: [],
        size: product.size,
        unit: product.unit,
        color: product.color,
        orderCap: product.orderCap,
        minOrderCap: minOrderCap,
        incrementor: product.incrementor,
        vegan: product.vegan,
        gst: product.gst,
        brand: product.brand,
        translations: product.translations,
        addonMultiselect: product.addonMultiselect,
        addons: product.addons,
        hasProcessed: true,
        store: product.store,
        inStock: product.inStock,
        isPreorderable: product.isPreorderable,
        discount: product.discount,
        discountBy: product.discountBy,
        discountIsActive: product.discountIsActive,
        discountedPrice: product.discountedPrice,
        discountApplicableForMembershipCustomers:
          product.discountApplicableForMembershipCustomers,
        category: product.category,
        isCombo: product.isCombo,
        comboItems: product.comboItems,
        basePrice: product.basePrice,
        pricing: product.pricing,
      };
      store.state.cart.items.push(productObject);
      this.updateDynamicPricing(productObject);
    }
    this.updateLocalStorage();
  },

  updateDynamicPricing(product) {
    if (!product.pricing || !product.pricing.length) return;
    const cartProduct = this.findProduct(product);
    cartProduct.price = this.getPrice(product);
  },

  remove(product) {
    const oldProduct = this.findProduct(product);
    const orderMinCap = this.getMinOrderCap(product);

    if (oldProduct) {
      if (orderMinCap) {
        if (oldProduct.qty > orderMinCap) {
          oldProduct.qty = oldProduct.qty - (oldProduct.incrementor || 1);
        } else {
          oldProduct.qty = oldProduct.qty - orderMinCap;
        }
      } else {
        oldProduct.qty = oldProduct.qty - 1;
      }
      if (oldProduct.selectedAddons)
        oldProduct.selectedAddons.splice(oldProduct.qty, 1);
      oldProduct.price = this.getPrice(product);
    }
    if (oldProduct && oldProduct.qty === 0)
      store.state.cart.items = store.state.cart.items.filter(
        (x) => x._id !== product._id
      );

    this.updateLocalStorage();
    return;
  },

  removeItem(product) {
    store.state.cart.items = store.state.cart.items.filter(
      (x) => x._id !== product._id
    );
    this.updateLocalStorage();
  },

  clear() {
    store.state.cart.items = [];
    this.updateLocalStorage();
  },

  updateLocalStorage() {
    const cart = store.state.cart;
    const cartString = JSON.stringify(cart.items);
    const encryptedString = Util.encrypt(cartString, cart.secretKey);
    localStorage[cart.cartKey] = encryptedString;
  },

  getMinOrderCap(product) {
    let minOrderCap = product.minOrderCap || product.incrementor || 1;

    //if there is a contract pricing for this product
    if (store.getters.isAuthenticated) {
      if (
        store.state.cart.contractItems &&
        store.state.cart.contractItems.length
      ) {
        const contractItem = store.state.cart.contractItems.find(
          (x) => x._id == product._id
        );
        if (contractItem && contractItem.minOrderCap)
          minOrderCap = contractItem.minOrderCap;
      }
    }

    return minOrderCap;
  },

  calculateDynamicPricing(product, price) {
    if (!product.pricing || !product.pricing.length) return price;
    const count = this.getCount(product);
    const pricing = product.pricing;

    pricing.forEach((item) => {
      if (count >= item.gqty) price = item.price;
    });

    return price;
  },

  getPrice(product) {
    if (!product.basePrice) product.basePrice = product.price;
    let price = this.calculateDynamicPricing(product, product.basePrice);
    const isAuthenticated = store.getters.authStatus;
    const cart = store.state.cart;
    const storeInfo = store.state.storeInfo;

    if (isAuthenticated) {
      //if there is a contract pricing for this product
      if (cart.contractItems && cart.contractItems.length) {
        const contractItem = cart.contractItems.find(
          (x) => x._id == product._id
        );
        if (contractItem) price = contractItem.price;
      }

      let discount = 0;
      const membership = store.state.userInfo.membership;
      if (membership && membership.discountWise === "product") {
        if (membership.discountBy === "percent")
          discount = (price * membership.discount) / 100;
        else if (price >= membership.discount) discount = membership.discount;
      }

      if (
        product.discountIsActive &&
        ((membership && product.discountApplicableForMembershipCustomers) ||
          !membership)
      ) {
        if (product.discountBy === "percent")
          discount = discount + (price * product.discount) / 100;
        else if (price >= product.discount)
          discount = discount + product.discount;
      }

      if (discount > 0) {
        discount = parseFloat(discount.toFixed(2));
        if (price >= discount) price = price - discount;
      }
    } else if (product.discountIsActive && product.discountedPrice) {
      price = product.discountedPrice;
    }

    if (!product.beforeTaxPrice) product.beforeTaxPrice = price;
    if (storeInfo.applyTax && storeInfo.taxType == "product" && product.gst)
      price = price + this.calculateTax(price, product.gst);
    return price;
  },

  isAdded(product) {
    return !!this.findProduct(product);
  },

  getCount(product) {
    return this.findProduct(product)?.qty || 0;
  },

  findProduct(product) {
    return store.state.cart.items.find((x) => x._id == product._id);
  },

  getTotal() {
    let itemsTotal = store.state.cart.items.reduce(
      (a, b) => a + b.price * b.qty,
      0
    );

    //calculations of add-ons
    store.state.cart.items.forEach((x) => {
      if (x.selectedAddons) {
        x.selectedAddons.forEach((i) => {
          if (i) itemsTotal = itemsTotal + i.reduce((a, b) => a + b.price, 0);
        });
      }
    });

    return itemsTotal;
  },

  calculateTax(amount, tax) {
    let taxAmount = 0;
    if (amount && tax)
      taxAmount = parseFloat(((amount * tax) / 100).toFixed(2));
    return taxAmount;
  },

  getIsPercent() {
    const storeInfo = store.state.storeInfo;
    return (
      storeInfo.deliveryChargeBy && storeInfo.deliveryChargeBy === "percent"
    );
  },

  getPercentDC(total, percent) {
    let amt = (total * percent) / 100;
    amt = parseFloat(amt.toFixed(2));
    return amt;
  },

  getCategoryWiseDC() {
    const storeInfo = store.state.storeInfo;
    if (!storeInfo.categoryWiseDeliveryCharge) return 0;

    const categories = [];
    store.state.cart.items.forEach((x) => {
      const tempDC = x.category.deliveryCharge;
      const tempDFA = x.category.deliveryFreeAbove;

      if (x.category) {
        const catTotal = x.price * x.qty;
        const hasProcessed = categories.find((c) => c._id == x.category._id);

        if (hasProcessed) {
          hasProcessed.categoryTotal += catTotal;
        } else {
          categories.push({
            _id: x.category._id,
            name: x.category.name,
            categoryTotal: catTotal,
            deliveryCharge: tempDC,
            deliveryFreeAbove: tempDFA,
          });
        }
      }
    });

    return this.getDeliveryChargeCategoryWise(categories);
  },

  getItemsCategoryWise() {
    let processedCategories = [];
    store.state.cart.items.forEach((x) => {
      const newItem = JSON.parse(JSON.stringify(x));
      const processedCategory = processedCategories.find(
        (pc) => pc._id == newItem.category._id
      );
      if (processedCategory) {
        processedCategory.categoryTotal =
          processedCategory.categoryTotal + newItem.price * newItem.qty;
        processedCategory.items.push(newItem);
      } else {
        let category = newItem.category;
        category.items = [newItem];
        category.categoryTotal = newItem.price * newItem.qty;
        processedCategories.push(category);
      }
    });
    return processedCategories;
  },

  getMainCategoryWiseDC() {
    const categories = this.getItemsMainCategoryWise();
    const deliveryCharge = this.getDeliveryChargeCategoryWise(categories);
    return deliveryCharge;
  },

  getDeliveryChargeCategoryWise(categories) {
    let cwdc = 0;
    if (categories && categories.length) {
      const isPercent = this.getIsPercent();
      categories.forEach((x) => {
        if (x.deliveryCharge > 0) {
          if (x.categoryTotal <= x.deliveryFreeAbove) {
            if (isPercent) {
              const total = x.categoryTotal;
              const dc = x.deliveryCharge;
              const catDC = this.getPercentDC(total, dc);
              cwdc += catDC;
            } else {
              cwdc += x.deliveryCharge;
            }
          }
        }
      });
    }
    return cwdc;
  },

  findParent(object, key) {
    if (!object) return;
    let value;
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === "object") {
        value = this.findParent(object[k], key);
        return value !== undefined;
      }
    });
    return value;
  },

  getItemsMainCategoryWise() {
    const processedCategories = [];
    store.state.cart.items.forEach((x) => {
      const newItem = JSON.parse(JSON.stringify(x));
      const categoryTree = store.state.cart.categoryTree.find(
        (c) => c._id == newItem.category._id
      );
      if (categoryTree && !categoryTree.parent) {
        const processedCategory = processedCategories.find(
          (pc) => pc._id == newItem.category._id
        );
        if (processedCategory) {
          processedCategory.categoryTotal =
            processedCategory.categoryTotal + newItem.price * newItem.qty;
          processedCategory.items.push(newItem);
        } else {
          let category = categoryTree;
          category.items = [newItem];
          category.categoryTotal = newItem.price * newItem.qty;
          processedCategories.push(category);
        }
      } else {
        const lastNode = this.findParent(categoryTree, "parent");
        if (lastNode) {
          const citem = lastNode.parent || lastNode;
          const processedCategory = processedCategories.find(
            (pc) => pc._id == citem._id
          );
          if (processedCategory) {
            processedCategory.categoryTotal =
              processedCategory.categoryTotal + newItem.price * newItem.qty;
            processedCategory.items.push(newItem);
          } else {
            let category = citem;
            category.items = [newItem];
            category.categoryTotal = newItem.price * newItem.qty;
            processedCategories.push(category);
          }
        }
      }
    });

    return processedCategories;
  },
};
